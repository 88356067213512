export const tours = [
  {
    id: 1,
    title: "3 Dates",
    clear_des_title: "3 Days 2 Nights",
    time: 3,
    time_detail: "3 Days 2 Nights",
    desc: "Lorem is spum sssssss",
    max_date: 3,
    present_url:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/3dt.jpg?alt=media&token=61cf18b6-41d3-4cf1-a855-b896fb265f88",
    exclude_txt : `Beverage, Bus tickets, Private room, tip for rider.`,
    include_txt: `Local Tourguide, 3 breakfasts, 3 lunches, 2 dinners, bunk beds, gastroline fee, ticket entrance fees, permit, motorbike and insurance for motorbike.`,
    help_txt: `When you book your tour package, you will receive a free dormitory bed at Our Hostel one night before the Start Date.`,
    short_desc: `We meet the tour guide and start the journey to conquer the beautiful Ha Giang loop, the beautiful winding pass road from Ha Giang. Going up to Thai An, the wonderful scenery below the pass is the clear blue river. and go deeper, cross Thai An pass and have lunch at a small streamside village in Thai An village. After lunch, continue going to Duong Thuong Valley, a rare beauty in Vietnam. We drive surrounded by towering mountains and cornfields next to the road`,
    detail: [
      {
        id: "si1",
        title: "Day 1",
        short_explain_title: "JOURNEY: HA GIANG - THAI AN - DU GIA",
        routes: [
          {
            id: "rd3t11",
            name: "Having a breakfast at the Hostel",
            short_desc: "start the journey at 8:30 am",
          },
          {
            id: "rd3t12",
            name: "Thai An Pass and Village",
            short_desc:
              "Cross the pass with wonderful sceneries and having lunch at village",
          },
          {
            id: "rd3t13",
            name: "Duong Thuong and Lung Ho Valley",
            short_desc:
              "After lunch, driving surrounded by towering mountains and cornfields",
          },
          {
            id: "rd3t14",
            name: "Du Gia waterfall and village",
            short_desc: "Having dinner, singing karaoke, and drinking",
          },
        ],
        map_img:
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/map1.jpg?alt=media&token=c5a554e6-f7dc-46e0-a336-ccab4a1ae187",
        desc: `
        At 8:00 am we wake up and have breakfast at the Hostel and prepare everything for the journey, 
        At 8:30 am we meet the tour guide and start the journey to conquer the beautiful Ha Giang loop, 
        the beautiful winding pass road from Ha Giang. Going up to Thai An, the wonderful scenery below the pass is the clear blue river. and go deeper,
        cross Thai An pass and have lunch at a small streamside village in Thai An village. After lunch, 
        continue going to Duong Thuong Valley, a rare beauty in Vietnam. 
        We drive surrounded by towering mountains and cornfields next to the road. Arriving at Du Gia in the afternoon, we will go to the fairy waterfall to relax after a day of sightseeing. 
        In the evening, we will enjoy a Vietnamese dinner and sing karaoke and drink happy water with everyone at the Homestay.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour31.jpg?alt=media&token=29598e69-d19d-4128-a8d2-1b9b1ee9f716",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour32.jpg?alt=media&token=858298b9-e75c-4cbf-83c1-064d356c65c6",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/bzz.jpg?alt=media&token=00e55a6a-8cfa-4f80-8959-b6b9dcbb96a0",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/bpl.jpg?alt=media&token=4fa495be-2324-4c22-ad46-3ca74175865e",
          // "https://res.cloudinary.com/hdprivatecloud/image/upload/v1702974537/posts/anhbg_gcqk0o.jpg",
        ],
      },
      {
        id: "si2",
        title: "Day 2",
        short_explain_title:
          "JOURNEY: DU GIA – MAU DUE – MA PI LENG – DONG VAN",
          routes: [
            {
              id: "rd4t21",
              name: "Ngam La Village",
              short_desc: "Sightseeing at the village and the valley",
            },
            {
              id: "rd4t22",
              name: "M Pass",
              short_desc: "Sightseeing at the village and the valley",
            },
            {
              id: "rd4t23",
              name: "Mau Due Village and Ma Pi Leng Pass",
              short_desc: "Having lunch and crossing the majestic mountains",
            },
            {
              id: "rd4t24",
              name: "Nho Que River",
              short_desc: "Taking a boat to enjoy the river",
            },
            {
              id: "rd4t25",
              name: "Dong Van Homestay",
              short_desc: "Having dinner and relaxing",
            },
          ],
        map_img:
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/map2.jpg?alt=media&token=7eeef38c-1666-46df-a673-eb3dc2e1363f",
        desc: `
         After breakfast, we say goodbye to the local homestay. First we will visit Ngam La village and the valley with stretching mountains creating an extremely majestic scene. Go to Mau Due village where lunch is served. After lunch, we continue to cross the majestic mountains to reach Ma Pi Leng Pass, one of the most beautiful passes in Vietnam. Looking down from above, the Nho Que river is like a shimmering green thread stretching down from the sky. Nestled among the vast mountains and forests of the Northeast, we continue to go to the Nho Que River to take a boat to enjoy the timeless beauty of the Nho Que River alongside the rocky mountains. Arriving in Dong Van in the late afternoon, we enjoy a local dinner and end a long day admiring the majestic scenery.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour321.jpg?alt=media&token=f7e4dedc-7a0e-4137-bc64-c584b02daae4",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour322.jpg?alt=media&token=6ebbf447-4ad6-4965-ae7e-9bd2d2aae4be",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid1.jpg?alt=media&token=7e4f99bd-672f-4068-8c47-df8d1052aa8f",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2F6.jpg?alt=media&token=2a53d830-0413-4736-82c1-b3a10d8e67f3",
        ],
      },
      {
        id: "si3",
        title: "Day 3",
        short_explain_title:
          "JOURNEY: DONG VAN – YEN MINH – QUAN BA – HA GIANG",
        routes: [
          {
            id: "rd3t31",
            name: "Chin Khoanh Pass",
            short_desc:
              "Great place to stop and admire the sets of curved roads",
          },
          {
            id: "rd3t32",
            name: "H'mong village",
            short_desc: "Visiting H'mong village",
          },
          {
            id: "rd3t33",
            name: "Tham Ma Pass",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd3t34",
            name: "Quan Ba Heaven's Gate",
            short_desc: "Visiting Quan Ba Heaven's gate",
          },
          {
            id: "rd3t35",
            name: "Bac Sum Pass",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd3t36",
            name: "Ha Giang City",
            short_desc: "Return to the hostel",
          },
        ],
        map_img:
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/map3.jpg?alt=media&token=9f1e62a3-8ea7-4b76-9487-8ce976dcc2ec",
        desc: `
        On the last day of the trip, we say goodbye to the homestay. Indeed, Dong Van Karst Plateau has become the first geopark recognized by UNESCO in Vietnam. Thousands of limestone rocks create a majestic and breathtaking scene. We continue our journey to the extremely famous pass here, Chin Khoanh pass, then to Lung Cam village, a Mong village with houses made of mud hundreds of years old. year old. Next we go through Pho Cao town to reach another famous pass, Tham Ma pass. We go to Tam Son town where lunch is served, after lunch we can admire the beauty of the mountain. Mount Tien and Tam Son town from above, we continue to the final pass, the beautiful winding Bac Sum Pass and return to Ha Giang in the late afternoon.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour3331.jpg?alt=media&token=5b33ef30-d559-4e24-9d11-1904fd7b6857",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/gf.jpg?alt=media&token=0c87ddfa-cb73-4e76-a49d-1a1de26d33e0",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour3333.jpg?alt=media&token=d6c3fbf4-7bc8-4ac2-b75a-f262a1d9ccb6",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/zio.jpg?alt=media&token=439e878b-3a20-4dfc-854f-15c4ed3f6cf4",
        ],
      },
    ],
    packages: [
      {
        id: 1,
        name: "Easyrider",
        note: "Highly Recommended",
        pricevnd: 3500000,
        pricedola: 140,
      },
      {
        id: 2,
        name: "Driving by yourself",
        note: "International motorbike driving license with motorbike driver",
        pricevnd: 2500000,
        pricedola: 100,
      },
    ],
  },
  {
    id: 2,
    title: "4 Dates",
    clear_des_title: "4 Days 3 Nights",
    time: 4,
    max_date: 4,
    time_detail: "4 Days 3 Nights",
    desc: "Lorem is spum sssssss",
    exclude_txt : `Beverage, Bus tickets, Private room, tip for rider.`,
    include_txt: `Local Tourguide, 4 breakfasts, 4 lunches, 3 dinners, bunk beds, gastroline fee, ticket entrance fees, permit, motorbike and insurance for motorbike.`,
    help_txt: `When you book your tour package, you will receive a free dormitory bed at Our Hostel one night before the Start Date.`,
    present_url:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/4dt.jpg?alt=media&token=d34f6778-2d96-4269-96da-415a9da36034",
    short_desc: `We will see a panoramic view of the mountains and forests of this area and the Vietnam-China border from afar. We then continue to the small village near the Lung Cu flagpole where lunch is served to us. We continued our journey to the extremely famous pass here, Chin Khoanh Pass, then to Lung Cam village, a village of the Hmong people, where there are houses made of soil hundreds of years old.`,
    detail: [
      {
        id: "si1",
        title: "Day 1",
        short_explain_title: "JOURNEY: HA GIANG - THAI AN - DU GIA",
        routes: [
          {
            id: "rd4t11",
            name: "Having a breakfast at the Hostel",
            short_desc: "start the journey at 8:30 am",
          },
          {
            id: "rd4t12",
            name: "Thai An Pass and Village",
            short_desc:
              "Cross the pass with wonderful sceneries and having lunch at village",
          },
          {
            id: "rd4t13",
            name: "Duong Thuong and Lung Ho Valley",
            short_desc:
              "After lunch, driving surrounded by towering mountains and cornfields",
          },
          {
            id: "rd4t14",
            name: "Du Gia waterfall and village",
            short_desc: "Having dinner, singing karaoke, and drinking",
          },
        ],
        map_img:
        "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/map1.jpg?alt=media&token=c5a554e6-f7dc-46e0-a336-ccab4a1ae187",
        desc: `
          At 8:00 am we wake up and have breakfast at the Hostel and prepare everything for the journey, At 8:30 am we meet the tour guide and start the journey to conquer the beautiful Ha Giang loop, the beautiful winding pass road from Ha Giang. Going up to Thai An, the wonderful scenery below the pass is the clear blue river. and go deeper, cross Thai An pass and have lunch at a small streamside village in Thai An village. After lunch, continue going to Duong Thuong Valley, a rare beauty in Vietnam. We drive surrounded by towering mountains and cornfields next to the road. Arriving at Du Gia in the afternoon, we will go to the fairy waterfall to relax after a day of sightseeing. In the evening, we will enjoy a Vietnamese dinner and sing karaoke and drink happy water with everyone at the Homestay.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour31.jpg?alt=media&token=29598e69-d19d-4128-a8d2-1b9b1ee9f716",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour32.jpg?alt=media&token=858298b9-e75c-4cbf-83c1-064d356c65c6",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/bzz.jpg?alt=media&token=00e55a6a-8cfa-4f80-8959-b6b9dcbb96a0",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/bpl.jpg?alt=media&token=4fa495be-2324-4c22-ad46-3ca74175865e",
          // "https://res.cloudinary.com/hdprivatecloud/image/upload/v1702974537/posts/anhbg_gcqk0o.jpg",
        ],
      },
      {
        id: "si2",
        title: "Day 2",
        short_explain_title:
          "JOURNEY: DU GIA – MAU DUE – MA PI LENG – DONG VAN",
        routes: [
          {
            id: "rd4t21",
            name: "Ngam La Village",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd4t22",
            name: "M Pass",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd4t23",
            name: "Mau Due Village and Ma Pi Leng Pass",
            short_desc: "Having lunch and crossing the majestic mountains",
          },
          {
            id: "rd4t24",
            name: "Nho Que River",
            short_desc: "Taking a boat to enjoy the river",
          },
          {
            id: "rd4t25",
            name: "Dong Van Homestay",
            short_desc: "Having dinner and relaxing",
          },
        ],
        map_img:
        "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/map2.jpg?alt=media&token=7eeef38c-1666-46df-a673-eb3dc2e1363f",
        desc: `
          After breakfast, we say goodbye to the local homestay. First we will visit Ngam La village and the valley with stretching mountains creating an extremely majestic scene. Go to Mau Due village where lunch is served. After lunch, we continue to cross the majestic mountains to reach Ma Pi Leng Pass, one of the most beautiful passes in Vietnam. Looking down from above, the Nho Que river is like a shimmering green thread stretching down from the sky. Nestled among the vast mountains and forests of the Northeast, we continue to go to the Nho Que River to take a boat to enjoy the timeless beauty of the Nho Que River alongside the rocky mountains. Arriving in Dong Van in the late afternoon, we enjoy a local dinner and end a long day admiring the majestic scenery.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour321.jpg?alt=media&token=f7e4dedc-7a0e-4137-bc64-c584b02daae4",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour322.jpg?alt=media&token=6ebbf447-4ad6-4965-ae7e-9bd2d2aae4be",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid1.jpg?alt=media&token=7e4f99bd-672f-4068-8c47-df8d1052aa8f",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2F6.jpg?alt=media&token=2a53d830-0413-4736-82c1-b3a10d8e67f3",
        ],
      },
      {
        id: "si3",
        title: "Day 3",
        short_explain_title: "JOURNEY: DONG VAN – LUNG CU FLAG POLE – YEN MINH",
        routes: [
          {
            id: "rd4t31",
            name: "LoLo Village",
            short_desc: "start the journey",
          },
          {
            id: "rd4t32",
            name: "Lung Cu Flag Pole",
            short_desc: "Visiting flagpole at the top of Viet Nam",
          },
          {
            id: "rd4t33",
            name: "H'mong village",
            short_desc: "Visiting H'mong village",
          },
          {
            id: "rd4t34",
            name: "Tham Ma Pass",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd4t35",
            name: "Yen Minh Town",
            short_desc: "Checking in at a local homestay and enjoy dinner",
          },
        ],
        map_img:
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/mapz5.jpg?alt=media&token=1159eab0-3cf7-4992-bc4b-a25050e6673d",
        desc: `
        After breakfast, we say goodbye to the homestay family to go to the northernmost point of Vietnam, which is the Lung Cu flagpole, the symbol of Vietnam. From the Lung Cu flagpole, we will see a panoramic view of the mountains and forests of this area and the Vietnam-China border from afar. We then continue to the small village near the Lung Cu flagpole where lunch is served to us. We continued our journey to the extremely famous pass here, Chin Khoanh Pass, then to Lung Cam village, a village of the Hmong people, where there are houses made of soil hundreds of years old. . Next, we go through Pho Cao town to reach another famous pass, Tham Ma pass. We arrive in Yen Minh town late in the afternoon, check in at a local homestay and enjoy a local evening meal here
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/wui.jpg?alt=media&token=0fa5bab2-e238-4d36-9628-0157578ec2b1",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/flg.jpg?alt=media&token=53385f92-dd9d-4fab-81ac-a30a0cb82236",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftour3331.jpg?alt=media&token=5b33ef30-d559-4e24-9d11-1904fd7b6857",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/gf.jpg?alt=media&token=0c87ddfa-cb73-4e76-a49d-1a1de26d33e0",
        ],
      },
      {
        id: "si4",
        title: "Day 4",
        short_explain_title: "JOURNEY: YEN MINH – QUAN BA – HA GIANG",
        routes: [
          {
            id: "rd4t41",
            name: "Visiting Yen Minh Pine Forest",
            short_desc: "Visiting Yen Minh Pine Forest",
          },
          {
            id: "rd4t42",
            name: "Lung Khuy Cave",
            short_desc: "Visiting Lung Khuy Cave",
          },
          {
            id: "rd4t43",
            name: "Quan Ba Heaven's gate",
            short_desc: "Visiting Quan Ba Heaven's gate",
          },
          {
            id: "rd4t44",
            name: "Pass Sum Pass",
            short_desc: "Sightseeing at the village and the valley",
          },
          {
            id: "rd4t45",
            name: "Ha Giang City",
            short_desc: "Return to the hostel",
          },
        ],
        map_img:
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/mapz6.jpg?alt=media&token=dffb45e6-49d7-4723-8044-69d6359407aa",
        desc: `
         After breakfast, we say goodbye to our homestay family to continue our journey to Tam Son town. On the way through the undulating hills of pine forests and terraced fields, we continue to visit the cave. The most beautiful cave in Ha Giang loop (Lung Khuy cave) you will admire the stalactite system and rough, dark yellow stone columns. Until you set foot inside, you will be overwhelmed by a diverse world of stalactites. We arrive at Tam Son town where lunch is served, after lunch the road will take you to Cong Troi where you can admire the beauty of the twin mountains and Tam Son town from above, we continue to go to the last pass. Bac Sum Pass is beautiful and winding. Return to Ha Giang in the late afternoon.
          `,
        galleries: [
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/ghj.jpg?alt=media&token=b48b00de-4ea7-4a4c-b5f7-048c581387a8",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/jl.jpg?alt=media&token=d9b48d7e-d73b-420d-bd5b-bf005add4719",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/fjk.jpg?alt=media&token=3577ea34-6ed4-4fcb-a731-764e905ac368",
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/loi.jpg?alt=media&token=fa6b7728-dcd5-43de-af4b-869d396e3f6c",
        ],
      },
    ],
    packages: [
      {
        id: 1,
        name: "Easyrider",
        note: "Highly Recommended",
        pricevnd: 4500000,
        pricedola: 180,
      },
      {
        id: 2,
        name: "Driving by yourself",
        note: "International motorbike driving license with motorbike driver",
        pricevnd: 3500000,
        pricedola: 140,
      },
    ],
  },
];

export const packages = [
  {
    id: 1,
    name: "Easyrider",
    note: "Highly Recommended",
    pricevnd: 2000000,
    pricedola: 80,
  },
  {
    id: 2,
    name: "Rinding by yourself",
    note: "International motorbike driving license with motorbike driver",
    pricevnd: 2000000,
    pricedola: 80,
  },
  // {
  //   id: 3,
  //   name: "Seat behind your friend",
  //   note: "Lorem Ipsum is simply dummy text of the printing",
  //   pricevnd: 2000000,
  //   pricedola: 80,
  // },
];

export const slidesTour = [
  {
    id: 1,
    title: "Looking Forward",
    short_text: "best your choice",
    title_box: "Best Service In Ha Giang",
    desc_box:
      "We always bring customers the best quality services for the whole trip. Sun Flower Tours is always ready to accompany you on every road.",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/foward1.jpg?alt=media&token=77f95f58-2ab0-472a-9a1e-ffd81f8a5143",
  },
  {
    id: 2,
    title: "Sun Flower",
    short_text: "best your choice",
    title_box: "Ha Giang Loop Tour",
    desc_box:
      "Sun Flower has many years of experience and deep understanding of the area, sharing useful information services about Ha Giang Loop and places to visit.",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/foward2.jpg?alt=media&token=177cea74-4016-474e-a594-82d0935dace9",
  },
];
export const slidesFHome = [
  {
    id: 1,
    title: "Ha Giang",
    short_text: "best your choice",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/homesli1.jpg?alt=media&token=1d3e19a7-e47a-4dc2-b136-b2e15b827fd5",
  },
  {
    id: 2,
    title: "Loop Tour",
    short_text: "best your choice",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/homesli22.jpg?alt=media&token=e89e44a1-8b40-4561-b2db-fe13487b82bd",
  },
];

export const dataCus = [
  {
    id: 1,
    img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1701156435/editors/289707778_2047438108773935_4471902040350767920_n_ly4ke0.jpg",
    img_review:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fdulic.jpg?alt=media&token=c2f08615-a90a-4a45-8d55-3acbefabdd64",
    rate: 2.5,
    name_cus: "Mark Smith",
    name_job_cus: "Travel Enthusiast",
    content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
in a piece of classical Latin literature from 45 BC.`,
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1701156435/editors/289707778_2047438108773935_4471902040350767920_n_ly4ke0.jpg",
    img_review:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fdulic.jpg?alt=media&token=c2f08615-a90a-4a45-8d55-3acbefabdd64",
    rate: 4.5,
    name_cus: "Mark Smith Loy",
    name_job_cus: "Travel Enthusiast Lo",
    content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
in a piece of classical Latin literature from 45 BC. sssss`,
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1701156435/editors/289707778_2047438108773935_4471902040350767920_n_ly4ke0.jpg",
    img_review:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fdulic.jpg?alt=media&token=c2f08615-a90a-4a45-8d55-3acbefabdd64",
    rate: 4.5,
    name_cus: "Mark Smith Loy",
    name_job_cus: "Travel Enthusiast Lo",
    content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
in a piece of classical Latin literature from 45 BC. sssss`,
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1701156435/editors/289707778_2047438108773935_4471902040350767920_n_ly4ke0.jpg",
    img_review:
      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fdulic.jpg?alt=media&token=c2f08615-a90a-4a45-8d55-3acbefabdd64",
    rate: 4.5,
    name_cus: "Mark Smith Loy",
    name_job_cus: "Travel Enthusiast Lo",
    content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
in a piece of classical Latin literature from 45 BC. sssss`,
  },
];

export const buses = [
  {
    id: "bus1",
    name: "Sleeper",
    time_range: [],
    price: 400000,
  },
  {
    id: "bus2",
    name: "Vip Cabin",
    time_range: [],
    price: 400000,
  },
  {
    id: "bus3",
    name: "Limousine",
    time_range: [],
    price: 400000,
  },
];

export const dataRenBuses = [
  {
    id: "hn1",
    des_name: "Ha Noi",
    contents: [
      {
        id: "drhn1",
        kind: "direct",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["8pm"],
            pricevnd: 250000,
            pricedola: 9.75,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
          {
            id: "bus2",
            name: "Vip Cabin",
            time_range: ["11am", "7.30pm"],
            pricevnd: 400000,
            pricedola: 15.8,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2FVIP%20sleeper%20cabin%20bus%20(2).jpg?alt=media&token=3f315a68-1faf-4980-b86d-1f8323a159a6",
          },
          {
            id: "bus3",
            name: "Limousine",
            time_range: ["6.15am", "3.30pm"],
            pricevnd: 350000,
            pricedola: 13.56,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Flimosine%20bus%20(2).jpg?alt=media&token=2d49970a-8976-47b8-9701-166a89868278",
          },
        ],
      },
      {
        id: "rhn1",
        kind: "return",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["9pm"],
            pricevnd: 250000,
            pricedola: 9.75,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
          {
            id: "bus2",
            name: "Vip Cabin",
            time_range: ["11am", "14.30pm", "9pm", "10pm"],
            pricevnd: 400000,
            pricedola: 15.8,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2FVIP%20sleeper%20cabin%20bus%20(2).jpg?alt=media&token=3f315a68-1faf-4980-b86d-1f8323a159a6",
          },
          {
            id: "bus3",
            name: "Limousine",
            time_range: ["6.15am", "3.30pm"],
            pricevnd: 350000,
            pricedola: 13.56,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Flimosine%20bus%20(2).jpg?alt=media&token=2d49970a-8976-47b8-9701-166a89868278",
          },
        ],
      },
    ],
  },
  {
    id: "sp1",
    des_name: "Sa Pa",
    contents: [
      {
        id: "drsp1",
        kind: "direct",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["7.30pm"],
            pricevnd: 250000,
            pricedola: 9.75,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
        ],
      },
      {
        id: "rtsp1",
        kind: "return",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["7.30pm"],
            pricevnd: 250000,
            pricedola: 9.75,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
        ],
      },
    ],
  },
  {
    id: "cb1",
    des_name: "Cát Bà Island",
    contents: [
      {
        id: "drcb1",
        kind: "direct",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["3.30pm"],
            pricevnd: 400000,
            pricedola: 15.8,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
        ],
      },
      {
        id: "rtcb1",
        kind: "return",
        time_pick: "",
        buses: [
          {
            id: "bus1",
            name: "Sleeper",
            time_range: ["7.30pm"],
            pricevnd: 400000,
            pricedola: 15.8,
            url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fgiu%CC%9Bo%CC%9B%CC%80ng%20na%CC%86%CC%80m%20thu%CC%9Bo%CC%9B%CC%80ng%202.jpg?alt=media&token=604daa40-6330-4b43-a2cc-4be17dff1b67",
          },
        ],
      },
    ],
  },
];

export const motorbikes = [
  {
    id: "mt1",
    name: "Honda Blade 110cc",
    pricevnd: 0,
    pricedola: 0,
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fxe1.jpg?alt=media&token=f5768b86-da79-4f95-bfd2-d1abd2451942",
  },
  {
    id: "mt2",
    name: "Honda Future 125cc",
    pricevnd: 250000,
    pricedola: 10,
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fxe2.jpg?alt=media&token=f509e08c-d3d3-49fa-b87a-2dfa982464b3",
  },
  {
    id: "mt3",
    name: "Honda XR 150cc",
    pricevnd: 500000,
    pricedola: 20,
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fx3.jpg?alt=media&token=ee2f4579-200d-45d2-9485-210275ea0b44",
  },
];

export const gift = [
  {
    id: "gif1",
    name: "Ha Giang T-shirt",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftn.png?alt=media&token=b4b48ec7-3379-4b17-827f-063a389fd89d",
    pricevnd: 0,
    pricedola: 0,
  },
];

export const rooms = [
  {
    id: "room1",
    name: "Dorm",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fdorm.jpg?alt=media&token=fec379ac-9e24-40e9-ae6d-18cd90e8b01d",
    pricevnd: 0,
    pricedola: 0,
  },
  {
    id: "room2",
    name: "Private Room",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fsinglethoutwin.png?alt=media&token=9b32e283-40c5-4a94-b5f5-c6cc89057c40",
    pricevnd: 200000,
    pricedola: 8,
  },
];

export const slideHome1 = [
  {
    id: 1,
    name_des: "Ma Pi Leng Pass",
    short_term: "Ma Pi Leng",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/mapi.jpg?alt=media&token=4008f51a-2a9e-41a0-9209-9e553c8df459",
    desc: "Ma Pi Leng Pass is a winding, zigzag pass, about 20km long with an average altitude of 1400 - 1600m, connecting Dong Van and Meo Vac districts in Ha Giang province. The pass is located in the Dong Van stone plateau area, recognized by UNESCO as a global geopark in 2010.",
  },
  {
    id: 2,
    name_des: "Lung Cu Flag Tower",
    short_term: "Lung Cu",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/lucu.jpg?alt=media&token=ad712d05-011c-4e81-8255-6207dc2db8ad",
    desc: "Lung Cu Flagpole is a national flag located at Lung Cu peak, also known as Dragon peak. Located at an altitude of 1470 above sea level, in Lung Cu commune, Dong Van district. During your trip to explore Ha Giang, you cannot miss visiting Lung Cu flagpole because this is a sacred historical work of the people of the whole country.",
  },
  {
    id: 3,
    name_des: "Nho Que River",
    short_term: "Nho Que",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/nhoqu.jpg?alt=media&token=a8fe3466-8e12-4c3e-943d-c85de8d4a56e",
    desc: "Originating from Nghiem Son mountain (Yunnan, China) at an altitude of 1,800m, Nho Que River in Ha Giang flows northeast, through wild and rugged mountains and forests, pouring into Lung Cu commune, Dong Van to enter Vietnam.",
  },
  {
    id: 4,
    name_des: "Du Gia Village",
    short_term: "Du Gia",
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/dug.jpg?alt=media&token=8eca75b9-5a92-440b-8c8d-c7dbb307287b",
    desc: "Located in the northernmost province of Vietnam, Du Gia is a lovely little village nestled amid the breathtaking natural scenery of Ha Giang. This is a paradise for those who love exploration and are passionate about nature.",
  },
];

export const slidelefthometour = [
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid2.jpg?alt=media&token=03b8916a-be78-42e9-a677-36712b0a4449",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid1.jpg?alt=media&token=7e4f99bd-672f-4068-8c47-df8d1052aa8f",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2F6.jpg?alt=media&token=2a53d830-0413-4736-82c1-b3a10d8e67f3",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid3.jpg?alt=media&token=dece8a61-4334-4373-983c-5e8a5293afe4",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid6.jpg?alt=media&token=8b8027f9-a119-400e-ae4d-c1e2c6eed7b8",
];
export const slidetripadvisors = [
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripa1.jpg?alt=media&token=07462746-c00d-41d3-82e6-13a8c5fbc66e",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripa2.jpg?alt=media&token=a2422455-a4af-4a35-90e0-be1bf914fec9",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripa3.jpg?alt=media&token=8c04b98b-b9c7-4aaf-aa7f-feb7cff830c0",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripa4.jpg?alt=media&token=677110f5-9bb9-4d92-88c4-e7aef03b3d19",
  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fslid6.jpg?alt=media&token=8b8027f9-a119-400e-ae4d-c1e2c6eed7b8",
];

export const tripadvisorImgs = [""];

export const testBlogs = [
  {
    id: 1,
    title: "Post 1",
    desc: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been 
    the industry's standard dummy text ever since 
    the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make 
    a type specimen book`,
    short_content: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry`,
    created_by: "Sun Flower Tour",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgh.jpg?alt=media&token=99150924-95c2-4cdb-9687-31ff3e727f61",
    is_delete: false,
    categories: "Healthy",
    createdAt: "2024-07-18T15:52:17.941+00:00",
  },
  {
    id: 2,
    title: "Post 2",
    desc: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been 
    the industry's standard dummy text ever since 
    the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make 
    a type specimen book`,
    short_content: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry`,
    created_by: "Sun Flower Tour",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgh.jpg?alt=media&token=99150924-95c2-4cdb-9687-31ff3e727f61",
    is_delete: false,
    categories: "Healthy",
    createdAt: "2024-07-18T15:52:17.941+00:00",
  },
  {
    id: 3,
    title: "Post 3",
    desc: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been 
    the industry's standard dummy text ever since 
    the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make 
    a type specimen book`,
    short_content: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry`,
    created_by: "Sun Flower Tour",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgh.jpg?alt=media&token=99150924-95c2-4cdb-9687-31ff3e727f61",
    is_delete: false,
    categories: "Healthy",
    createdAt: "2024-07-18T15:52:17.941+00:00",
  },
  {
    id: 4,
    title: "Post 4",
    desc: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been 
    the industry's standard dummy text ever since 
    the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make 
    a type specimen book`,
    short_content: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry`,
    created_by: "Sun Flower Tour",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgh.jpg?alt=media&token=99150924-95c2-4cdb-9687-31ff3e727f61",
    is_delete: false,
    categories: "Healthy",
    createdAt: "2024-07-18T15:52:17.941+00:00",
  },
  {
    id: 5,
    title: "Post 5",
    desc: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been 
    the industry's standard dummy text ever since 
    the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make 
    a type specimen book`,
    short_content: `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry`,
    created_by: "Sun Flower Tour",
    img: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgh.jpg?alt=media&token=99150924-95c2-4cdb-9687-31ff3e727f61",
    is_delete: false,
    categories: "Healthy",
    createdAt: "2024-07-18T15:52:17.941+00:00",
  },
];

export const ourgallaries = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz1.jpg?alt=media&token=c5af74fd-7335-4509-af6b-37efeff22932",
    type: "img",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz2.jpg?alt=media&token=79c8870c-1844-48bf-8884-677a7a3a03cb",
    type: "img",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz3.jpg?alt=media&token=e994d965-439d-4764-af5b-44bd7ed78496",
    type: "img",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz4.jpg?alt=media&token=e404fceb-e4b0-4837-96f5-1fe0e8835746",
    type: "img",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz5.jpg?alt=media&token=d4eaa702-c0b2-4a2b-b5d7-c9e769eceb30",
    type: "img",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripz6.jpg?alt=media&token=de33be5a-ae75-4fbc-8890-598551fdddc8",
    type: "img",
  },
];
