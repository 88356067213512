import React from "react";
import { Form, Input, Button, message } from "antd";
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../../redux/actions/authAction";
import { emailValidate } from "../../../../utils/validations";
import "../auth.scss";

const FormItem = Form.Item;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    dispatch(login(e, navigate, message));
  };




  return (
    <div className={"layout-login"}>
      <div className="demo-logo">
        <img src={"https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/logofooter.png?alt=media&token=10732447-6259-40e1-bcf5-b759f2336661"} alt="Logo Admin Sun Flower" />
      </div>
      <Form onFinish={handleSubmit} className="login-form">
        <FormItem
          name={"email"}
          rules={[
            { required: true, message: "Email không được để trống !" },
            {
              pattern: new RegExp(emailValidate),
              message: "Hãy nhập đúng format email !",
            },
          ]}
          className={"form-i"}
        >
          <Input placeholder="Email" prefix={<FaRegUser color="#ACADA8" />} />
        </FormItem>
        <FormItem
          name={"password"}
          rules={[{ required: true, message: "Please enter your password !" }]}
          className={"form-i"}
        >
          <Input.Password
            type="password"
            placeholder="Password"
            prefix={<RiLockPasswordLine color="#ACADA8" />}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className={"form-button-submit"}
          >
            ĐĂNG NHẬP
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default Login;
