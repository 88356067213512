import React from "react";

export default function useViewPort() {
  const [offsets, setOffSets] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });


  React.useEffect(() => {
    const handleWindowResize = () =>
      setOffSets({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { offsets };
}
