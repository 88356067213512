import { postDataAPI } from "../../utils/fetchData";
import { clearToken, setToken } from "../../utils/storage";

export const TYPES = {
  AUTH_REQUEST_LOADING: "AUTH_REQUEST_LOADING",
  AUTH_REQUEST_SUCCESS: "AUTH_REQUEST_SUCCESS",
  AUTH_REQUEST_ERROR: "AUTH_REQUEST_ERROR",
  AUTH_EXIT: "AUTH_EXIT",
};
export const login = (data, navigate, message) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.AUTH_REQUEST_LOADING,
    });

    const res = await postDataAPI("auth/login", data);
    if (res) {
      dispatch({
        type: TYPES.AUTH_REQUEST_SUCCESS,
        payload: { user: res.data?.user, token: res.data?.token },
      });
      setToken(res.data?.token);
      navigate("/dashboard", {replace: true});
      message.success("Đăng nhập thành công!")
    }
  } catch (error) {
    dispatch({
      type: TYPES.AUTH_REQUEST_ERROR,
      payload: error.response?.message || error.response?.data?.msg,
    });
    message.error(error.response?.message || error.response?.data?.msg);
  }
};
export const updatProfile = (data, navigate) => async (dispatch) => {
    if (data) {
      dispatch({
        type: TYPES.AUTH_REQUEST_SUCCESS,
        payload: { user: data.user, token: data.token },
      });
    }
};

export const register = (data) => async (dispatch) => {
  try {
  } catch (err) {}
};
export const refreshToken = (data) => async (dispatch) => {
  try {
    const res = await postDataAPI("auth/refresh", data);
    if (res) {
      dispatch({
        type: TYPES.AUTH_REQUEST_SUCCESS,
        payload: { user: res.data?.user, token: res.data?.access_token },
      });
      setToken(res.data?.access_token);
    }
  } catch (error) {
    dispatch({
      type: TYPES.AUTH_REQUEST_ERROR,
      payload: error.response?.message || error.response?.data?.msg,
      status: "refresh"
    });
  }
};

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.AUTH_EXIT });
    clearToken();
    navigate("/");
  } catch (err) {}
};
