import { Button, Form, Input, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  dataDefaultFetch,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../../utils/fetchData";
import { getBase64 } from "../../../utils/readFile";
import { CKEditor5 } from "../../../utils/CKEditor5";
import { useSelector } from "react-redux"
import { matches } from "../../../utils/\bconstants"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { storage } from "../../../app/firebase"
import { v4 } from "uuid"
import ModalImage from "../../../components/_shared/Modals/ModalImage"
const FormItem = Form.Item;

export default function AddBlog() {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [onePro, setOnePro] = useState(dataDefaultFetch);
  const auth = useSelector((state) => state.auth);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (onePro.data) {
      const editData = { ...onePro.data, img: [onePro.data?.img] };
      form.setFieldsValue(editData);
    }
  }, [onePro.data, form, navigate, id]);


  if (id && onePro.error) {
    navigate("/dashboard/blog");
  }

  useEffect(() => {
    if (id) {
      (async () => {
        setOnePro({ ...onePro, loading: true });
        try {
          const res = await getDataAPI(`blog/one/${id}`);

          setOnePro({ ...onePro, data: res.data?.data, loading: false });
        } catch (error) {
          setOnePro({ ...onePro, data: null, loading: false, error: error });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChangeEditor = (event, editor) => {
    const dataEditorDes = editor.getData();
    form.setFieldValue("desc", dataEditorDes);
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    if (file.size > 100 * 1024 * 1024) {
      onError("File not larger than 100MB !");
      //   message.error("File not larger than 9MB !");
    } else if (!file.name.match(matches.img)) {
      onError("File is not the image!");
    } else {
      const storageRef = ref(storage, `ediors5/${file.name + v4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          onProgress(progress)
        },
        (error) => {
          alert(error);
          onError(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            onSuccess(downloadURL);
          });
        }
      );
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = async (e) => {
    let editedValues = { ...e };
    if (editedValues.img?.length < 1) {
      editedValues = { ...editedValues, img: [] };
    } else {
      if (editedValues.img[0].status === "error") {
        message.error("Lỗi up ảnh! Thử lại");
      } else {
        editedValues = {
          ...editedValues,
          img: {
            uid: e.img[0].uid,
            url: e.img[0].response || e.img[0].url,
            name: e.img[0].name,
            status: e.img[0].status,
          },
        };
        if (!id) {
          try {
            await postDataAPI("blog/add", editedValues, auth?.token);
            message.success("Tạo thành công bài viết !");
            form.resetFields();
          } catch (error) {
            message.error(
              error?.response?.data?.statusText || error?.response?.data?.msg
            );
          }
        } else {
          try {
            await patchDataAPI(`blog/edit/${id}`, editedValues,  auth?.token);
            message.success("Sửa thành công bài viết!");
          } catch (error) {
            message.error(
              error?.response?.data?.statusText || error?.response?.data?.msg
            );
          }
        }
      }
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        className="login-form"
      >
        <FormItem
          name="img"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please select a file!",
            },
          ]}
        >
          <Upload
            customRequest={uploadImage}
            listType="picture-card"
            beforeUpload={(file) => {
              if (file && file.size / 1024 / 1024 > 9) {
                message.error("Dung lượng quá tải");
                return Upload.LIST_IGNORE;
              } else {
                return true;
              }
            }}
            onPreview={handlePreview}
            accept={"image/*"}
            maxCount={1}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
        </FormItem>

        <ModalImage
          previewImage={previewImage}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          handleCancel={handleCancel}
        />
        <FormItem
          name={"title"}
          label="Tiều đề"
          rules={[{ required: true, message: "Please enter the title !" }]}
        >
          <Input placeholder="Tiêu đề" />
        </FormItem>
        <Form.Item
          name="categories"
          rules={[{ required: true, message: "Please type categories!" }]}
          label="Thể loại bài viết"
        >
          <Input placeholder="Thể loại" />
        </Form.Item>

        <FormItem
          name={"short_content"}
          label="Mô tả"
          rules={[{ required: true, message: "Please enter the desc !" }]}
        >
          <Input.TextArea placeholder="Hãy nhập mô tả ngắn" showCount style={{height: "160px"}}/>
        </FormItem>

        <FormItem
          name={"desc"}
          label="Mô tả"
          rules={[{ required: true, message: "Please enter the desc !" }]}
        >
          <CKEditor5
            onChange={onChangeEditor}
            onReady={(editor) => console.log(editor)}
            data={onePro.data ? onePro.data?.desc : ""}
          />
        </FormItem>
        <FormItem>
          <Button
            style={{ width: "100%", background: "#03396c", height: "37px" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {id ? "Sửa" : "Thêm"}
          </Button>
        </FormItem>
      </Form>
    </>
  );
}
