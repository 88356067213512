import { useEffect, useState } from "react";
import { Space, Table, Button, Input, Skeleton, message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { dataDefaultFetch, postDataAPI } from "../../../utils/fetchData";
import "../admin.scss";
import moment from 'moment';
import { useSelector } from "react-redux"
import { white_space_String } from "../../../utils/validations";
import "./post.scss";

const { Search } = Input;

export default function MainBlog() {
  const [searchFilter, setSearchFilter] = useState("");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState({
    page: 0,
    limit_page: 5,
    filters: {},
  });

  const [open, setOpen] = useState(false);

  const onSearchData = (data) => {
    if (!data) {
      const reset = { ...page };
      delete reset.filters["$text"];
      setPage(reset);
    } else {
      setPage({
        ...page,
        filters: {
          ...page.filters,
          $text: { $search: white_space_String(data) },
        },
      });
    }
  };

  const [blogs, setBlogs] = useState(dataDefaultFetch);

  const del = async () => {
    try {
      await postDataAPI(`blog/del`, { isDel: true, ids: selectedRowKeys }, auth?.token);
      message.success(`Xoá thành công bài !`);
      const newblogs = [...blogs.data];
      setBlogs({
        ...blogs,
        data: newblogs.filter((v) => !selectedRowKeys.includes(v._id)),
      });
      setOpen(false);
      const reset = { ...page };
      delete reset.filters["$text"];
      setPage(reset);
      setSelectedRowKeys([]);
      setSearchFilter("");
    } catch (error) {
      message.error(
        error?.response?.data?.statusText || error?.response?.data?.msg
      );
    }
  };

  const cancelModal = () => {
    setOpen(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    const resetF = { ...page };

    resetF.filters["is_delete"] = false;
    setPage(resetF);

    if (blogs.data?.length < 1 && page.page >= 0) {
      setPage({ ...page, page: --page.page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getBlog() {
    try {
      setBlogs({ ...blogs, loading: true });
      const res = await postDataAPI("blog/all", page);

      setBlogs({
        ...blogs,
        data: res.data?.data,
        loading: false,
        total: res.data?.total,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      setBlogs({ ...blogs, data: null, loading: false, error: error });
    }
  }

  useEffect(() => {
    getBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // select del all

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading] = useState({
    delSel: false,
    reSel: false,
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const savedData =
    !blogs.loading && Array.isArray(blogs?.data) && blogs?.data.length > 0
      ? blogs?.data.filter((i) => !i.is_delete).map((i) => {
          return {
            ...i,
            key: i._id,
          };
        })
      : [];

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Thumnals",
      dataIndex: "img",
      key: "img",
      render: (i) => (
        <>
          <img src={i.url} alt="img" width="50px" />
        </>
      ),
      responsive: ["lg"],
    },
    {
      title: "Created Time",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (time) => (
        <>
          <span>{moment(new Date(time)).format("DD/MM/YYYY")}</span>
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (blog) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setSelectedRowKeys([blog._id]);
            }}
            danger
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              navigate(`/dashboard/blog/edit/${blog._id}`);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {blogs.loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="actionsMoreWrap flex-row">
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={!hasSelected}
                loading={loading.delSel}
              >
                Xoá đã chọn
              </Button>
              {/* {recycles && (
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  loading={loading.reSel}
                  onClick={startRestoreSelected}
                  style={{ marginLeft: "20px" }}
                >
                  Restore đã chọn
                </Button>
              )} */}
              {/* <Button
                onClick={() => setRecycles(!recycles)}
                style={{ marginLeft: "20px" }}
                type="primary"
              >
                {!recycles ? "Thùng rác" : "Các sản phẩm"}
              </Button> */}
              <span
                style={{
                  marginLeft: 8,
                  fontSize: "12px",
                }}
              >
                {hasSelected
                  ? `Đã chọn ${selectedRowKeys.length} bài viết`
                  : ""}
              </span>
            </div>

            <div className="flex-row">
              <Search
                placeholder="Tìm kiếm bài viết..."
                onSearch={onSearchData}
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                enterButton
                allowClear
              />
              <Button
                onClick={() => navigate("/dashboard/blog/add")}
                type="primary"
                style={{ marginLeft: "15px" }}
              >
                Thêm bài viết
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={savedData}
            pagination={{
              total: blogs?.total,
              pageSize: page.limit_page,
              current: page.page + 1,
              onChange: (e) => setPage({ ...page, page: --e }),
            }}
          />
        </>
      )}
      <Modal open={open} onCancel={cancelModal} onOk={del}>
        Bạn có chắc xoá bài viết ?
      </Modal>
    </>
  );
}
