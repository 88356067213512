import { Button, Form, Input, Upload, message } from "antd";
import { FaRegUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import ModalImage from "../../../components/_shared/Modals/ModalImage";
import { getBase64 } from "../../../utils/readFile";
import { patchDataAPI } from "../../../utils/fetchData";
import { matches } from "../../../utils/\bconstants"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { storage } from "../../../app/firebase"
import { v4 } from "uuid"
const FormItem = Form.Item;

export default function Profile() {
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [, setNumLen] = useState(0);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        thumbnail: user.thumbnail ? [{ ...user.thumbnail }] : [],
        full_name: user.full_name,
        email: user.email,
      });
    }
  }, [user, form]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    if (file.size > 100 * 1024 * 1024) {
      onError("File not larger than 100MB !");
      //   message.error("File not larger than 9MB !");
    } else if (!file.name.match(matches.img)) {
      onError("File is not the image!");
    } else {
      const storageRef = ref(storage, `ediors5/${file.name + v4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          onProgress(progress)
        },
        (error) => {
          alert(error);
          onError(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            onSuccess(downloadURL);
          });
        }
      );
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = async (e) => {
    let editedValues = { ...e };
    if (editedValues.thumbnail?.length < 1) {
      editedValues = { ...editedValues, thumbnail: {} };
    } else {
      if (editedValues.thumbnail[0].status === "error") {
        message.error("Lỗi up ảnh! Thử lại");
        return;
      } else {
        editedValues = {
          ...editedValues,
          thumbnail: {
            uid: e.thumbnail[0].uid,
            url: e.thumbnail[0].response || e.thumbnail[0].url,
            name: e.thumbnail[0].name,
            status: e.thumbnail[0].status,
          },
        };
      }
    }

    try {
      await patchDataAPI(`auth/update-account/${user._id}`, editedValues);
      message.success("Cập nhập thành công!");
    } catch (error) {
      message.error(error?.response?.statusText || error?.response?.data?.msg);
    }
  };

  const onChangeImgs = async (fileList) => {
    setNumLen(fileList.fileList.length);
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
        form={form}
        className="login-form"
      >
        <FormItem
          name="thumbnail"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            customRequest={uploadImage}
            listType="picture-card"
            onChange={onChangeImgs}
            beforeUpload={(file) => {
              if (file && file.size / 1024 / 1024 > 8) {
                message.error("Dung lượng quá tải");
                return Upload.LIST_IGNORE;
              } else {
                return true;
              }
            }}
            onPreview={handlePreview}
            accept={"image/*"}
            maxCount={1}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
        </FormItem>

        <ModalImage
          previewImage={previewImage}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          handleCancel={handleCancel}
        />
        <FormItem name={"full_name"}>
          <Input
            placeholder="Username"
            prefix={<FaRegUser color="#ACADA8" />}
          />
        </FormItem>
        <FormItem
          rules={[{ required: true, message: "Email không được bỏ trống !" }]}
          name={"email"}
        >
          <Input
            disabled={true}
            name={"email"}
            placeholder="Email"
            prefix={<FaRegUser color="#ACADA8" />}
          />
        </FormItem>
        <FormItem>
          <Button
            style={{ width: "100px", background: "#03396c", height: "37px" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Lưu
          </Button>
        </FormItem>
      </Form>
    </>
  );
}
