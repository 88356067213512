import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Undo,
  Paragraph,
  Heading,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  AutoLink,
  Link,
  MediaEmbed,
  PasteFromOffice,
  TextTransformation,
  CloudServices,
  BlockQuote,
  IndentBlock,
  FontColor,
  FontFamily,
  FontSize,
  Alignment,
  FontBackgroundColor,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  CKBoxImageEdit,
  LinkImage,
  ImageResize,
  ImageResizeEditing,
  ImageResizeHandles,
  PictureEditing,
  CKBoxImageEditUI,
  CKBoxImageEditEditing,
  CKBoxEditing,
  Indent,
  List,
} from "ckeditor5";

import { matches } from "./\bconstants";
import { storage } from "../app/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 } from "uuid";

class customeUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.loader.file.then((files) => {

        if (files.size > 100 * 1024 * 1024) {
          reject("File not larger than 100MB !");
          //   message.error("File not larger than 9MB !");
        } else if (!files.name.match(matches.img)) {
          reject("File is not the image!");
        } else {
          const storageRef = ref(storage, `ediors5/${files.name + v4()}`);
          const uploadTask = uploadBytesResumable(storageRef, files);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              this.loader.uploadTotal = progress;
            },
            (error) => {
              alert(error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve({ default: downloadURL });
              });
            }
          );
        }
      });
    });
  }
}

export const CKEditor5 = ({ onChange, onReady, data }) => {
  const uploadPluginExtra = function (editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new customeUploadAdapter(loader);
    };
  };
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data ? data : ""}
      onChange={onChange}
      onReady={onReady}
      config={{
        toolbar: {
          items: ["undo", "redo", "|", "bold", "italic"],
        },
        plugins: [
          Essentials,
          Bold,
          Italic,
          Paragraph,
          FontFamily,
          FontSize,
          FontColor,
          FontBackgroundColor,
          Alignment,
          Heading,
          Table,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Link,
          AutoLink,
          MediaEmbed,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageResize,
          Indent,
          IndentBlock,
          List,
          Undo,
          Mention,
          LinkImage,
          PasteFromOffice,
          TextTransformation,
          CKBoxImageEdit,
          PictureEditing,
          CKBoxImageEditUI,
          CKBoxImageEditEditing,
          CKBoxEditing,
          CloudServices,
          BlockQuote,
          uploadPluginExtra,
        ],
        toolbar: [
          "bold",
          "italic",
          "|",
          "fontColor",
          "fontBackgroundColor",
          "fontFamily",
          "fontSize",
          "|",
          "alignment",
          "bulletedList",
          "numberedList",
          "heading",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "imageUpload",
          "blockQuote",
          "undo",
          "redo",
          "|",
          "outdent",
          "indent",
        ],
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        image: {
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              label: "Original",
            },
            {
              name: "resizeImage:custom",
              label: "Custom",
              value: "custom",
            },
            {
              name: "resizeImage:40",
              value: "40",
              label: "40%",
            },
            {
              name: "resizeImage:60",
              value: "60",
              label: "60%",
            },
          ],
          toolbar: [
            "imageTextAlternative",
            "toggleImageCaption",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
            "resizeImage",
            "linkImage"

            // "ckboxImageEdit",
          ],
        },
      }}
    />
  );
};
