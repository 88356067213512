import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return error;
  }
};




export const emailValidate = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const white_space_String = (string) => {
  let searchTerm =""
  string.split(" ").forEach(function(s) {
      if (s.search(/\./) !== -1) { s = "\""+s+"\""; }
      searchTerm += s + " ";
  })

  return searchTerm.trim();
}