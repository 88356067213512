import React from "react";
import BannerFiForPage from "../../components/_shared/ui-blocks/BannerFiForPage";
import "./index.scss";
import { Button, Form, Input, message } from "antd";
import { emailValidate } from "../../utils/\bconstants";
import { PhoneInput } from "react-international-phone";

import "react-international-phone/style.css";
import { isPhoneValid } from "../../utils/validations";
import { postDataAPI } from "../../utils/fetchData"
import { useTitle } from "../../hooks/useTitle"

const { TextArea } = Input;

export default function Contact() {
  const [form] = Form.useForm();

  const [values, setValues] = React.useState({});

  const isValid = values["phone"] ? isPhoneValid(values["phone"]) : true;
  const onFinish = async (values) => {
    const sendmail = `
    <h1>Liên Hệ</h1>

    <p>Tên: ${values.name}</p>
    <p>Email: ${values.email}</p>
    <p>Nội dung: ${values.message}</p>
    <p>Phone: ${values.phone}</p>
    `;

    try {
      await postDataAPI("auth/send-mail", {
        email: "info.hgmotorbiketours@gmail.com",
        title: `Liên hệ từ ${values.name}`,
        content: sendmail,
      });
      message.success("Gửi liên hệ thành công!");
      form.resetFields();
    } catch (error) {
      message.error("Kiểm tra lại dữ liệu gửi hoặc lỗi hệ thống");
    }
  };

  useTitle("Contact Us")
  return (
    <div className="contact-wrap">
      <BannerFiForPage title={"Contact us"} />'
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="header-txt-homep">Send us a message</h2>
            <p>
              Please contact us for further assistance and questions about the
              whole trip.
            </p>

            <Form
              variant="filled"
              layout="vertical"
              onValuesChange={(v) => setValues(v)}
              form={form}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please type your name!" }]}
              >
                <Input placeholder="Your Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address!",
                  },
                  {
                    pattern: new RegExp(emailValidate),
                    message: "Please enter your right email format!",
                  },
                ]}
              >
                <Input placeholder="a@gmail.com" />
              </Form.Item>

              <Form.Item
                name="phone"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (getFieldValue("phone").length > 1 && isValid) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Please type right format phone number!"
                      );
                    },
                  }),
                ]}
              >
                <PhoneInput
                  defaultCountry="vn"
                  disableFormatting={true}
                  inputStyle={{
                    height: "56px",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    border: "none",
                    width: "100%",
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      height: "56px",
                      padding: "0 7px",
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      border: "none",
                    },
                    buttonContentWrapperStyle: {},
                  }}
                  onChange={(phone) => {
                    // form.setFieldValue("phone", phone);
                    isPhoneValid(phone);
                  }}
                />
              </Form.Item>
              <Form.Item name="message">
                <TextArea
                  placeholder="Some Message..."
                  showCount
                  style={{ height: "185px" }}
                />
              </Form.Item>

              <Form.Item className="btnsm-wrap">
                <Button
                  style={{}}
                  className="btn-submit-contact"
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              </Form.Item>
            </Form>
            <div className="line-t">

              
            </div>
            <ul>
              <li><span>Address:</span> No 86 Dai Lo Huu Nghi, Ha Giang City, Viet Nam</li>
              <li><span>Email:</span>  info.hgmotorbiketours@gmail.com</li>
              <li><span>Whatsapp 24/7:</span>  +84327258338</li>
              
            </ul>
          </div>
        </div>
      </div>
      <div className="map-contact">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.911172116067!2d104.9754435!3d22.805755099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x36cc79a041095cf7%3A0x8267f105c92f0cac!2sHG%20Hostel%20%26%20Motorbikes!5e0!3m2!1svi!2s!4v1725626846606!5m2!1svi!2s"
          loading={"lazy"}
          width={"100%"}
          //   referrerpolicy="no-referrer-when-downgrade"
          title="ban do ha giang tour loop"
        />
      </div>
    </div>
  );
}
