import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./main.scss";
import { Outlet } from "react-router-dom";
import { Popover } from "antd";
import { FaChevronUp } from "react-icons/fa6";
import { TbMessageCircle2Filled } from "react-icons/tb";

export default function Main() {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const content = (
    <div className="wrapIcsC">
      <a
        href="https://www.instagram.com/hgtours_hagiangloop?igsh=d2ppZnIxbmxxM3Nr&utm_source=qr"
        alt="link-ins"
        target="_blank"
        rel="noreferrer"
        itemProp="url"
      >
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Finsta.png?alt=media&token=02e1b461-6af1-4ee6-beed-630012816721"} alt="fbic" />
        </div>
        <div>
          <p className="til">Instagram:</p>
          <p>@hgtours_hagiangloop</p>
        </div>
      </a>
      <a
        href={`https://wa.me/0327258338?text=Welcome to SunFlower Tours Hotline!`}
        alt="link-whatsapp"
        target="_blank"
        rel="noreferrer"
        itemProp="url" 
      >
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fwhatapp-removebg-preview.png?alt=media&token=0abd9467-8454-4802-a616-f0508297601c"} alt="waic" />
        </div>
        <div>
          <p className="til">Whatsapp:</p>
          <div className="fl">
            <p>0327 258 338</p>
            <p>Nhắn tin</p>
          </div>
        </div>
      </a>
    </div>
  );
  return (
    <>
      <Header scrollPosition={scrollPosition}/>
      <>
        <Outlet />
      </>
      <Footer />

      <Popover content={content} trigger="click" placement="bottomLeft">
        <div className="ic-socials">
          <div className="icwrap">
            <div>
              <TbMessageCircle2Filled />
              <div className="wave"></div>
            </div>
          </div>
        </div>
      </Popover>
        <div
          className="ic-scroll"
          style={{visibility: scrollPosition > 200 ? 'visible' : 'hidden'}}
          onClick={() =>  document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto", // Optional if you want to skip the scrolling animation
          })}
        >
          <div className="icwrap">
            <div>
              <FaChevronUp />
            </div>
          </div>
        </div>
    </>
  );
}
