// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUyWW4UMz4VR_MNkZNd20dsrPZEPWnvLs",
  authDomain: "hagiangnewtour.firebaseapp.com",
  projectId: "hagiangnewtour",
  storageBucket: "hagiangnewtour.appspot.com",
  messagingSenderId: "418653650445",
  appId: "1:418653650445:web:deb9949f59c82e13bedf44",
  measurementId: "G-BQ9QN4572P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
