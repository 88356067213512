import React from "react";
import { MTIc, VSIc } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { IoLogoInstagram } from "react-icons/io5";
import { LiaTripadvisor } from "react-icons/lia";

export default function Footer() {
  const [keys] = React.useState([
    { id: 1, key: "/", active: true, txt: "Home" },
    { id: 2, key: "/tour", active: false, txt: "Tours" },
    { id: 3, key: "/book", active: false, txt: "Book" },
    { id: 4, key: "/blog", active: false, txt: "News" },
    { id: 5, key: "/contact", active: false, txt: "Contact" },
  ]);

  const location = useLocation();
  return (
    <footer className="wrapFooter">
      <div className="bg-footer">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/ft.jpg?alt=media&token=9e0b8466-c68d-4c1e-a42f-2bf6ea505f0f"
          alt="imgfooter"
        />
      </div>

      <div className="container">
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/newlopfot-removebg-preview.png?alt=media&token=1e2ffb9e-cb0b-4b63-8889-a6b2cf8ddd4d"
          }
          className="logom"
          alt="Logo Ha Giang Loop Tour"
        />

        <p>Enjoy your trip with our best service in this city.</p>
        {/* <h4>HA GIANG CO, LTD</h4>
        <p>Internal Tour Operator Licence</p>
        <p>NO: 313-4/CLDASF-DTHH</p> */}

        <h4 className="space1">ADDRESS:</h4>
        <p>
          <span>Ha Giang HeadQuaters:</span> No 86 Dai Lo Huu Nghi, Ha Giang City, Viet Nam
        </p>

        <ul className="menus-footer">
          {keys.map((i, k) => (
            <li key={k}>
              <Link
                to={i.key}
                className={`${
                  i.key === location.pathname ? "active-menu-footer" : " "
                }`}
              >
                {i.txt}
              </Link>
            </li>
          ))}
        </ul>

        <p>Ha Giang Loop Tour | No 86 Dai Lo Huu Nghi, Ha Giang City, Viet Nam</p>
        <p>
          <span>Whatsapp: (+84) 3272 58338</span>
          <span> | Email: info.hgmotorbiketours@gmail.com</span>
        </p>

        <ul className="ics-social">
          <li>
            <a
              href={`https://wa.me/0327258338?text=Welcome to SunFlower Tours Hotline!`}
              alt="link-whatsapp"
              target="_blank"
              rel="noreferrer"
              itemProp="url"
            >
              <BsWhatsapp />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/hgtours_hagiangloop?igsh=d2ppZnIxbmxxM3Nr&utm_source=qr"
              alt="link-ins"
              target="_blank"
              rel="noreferrer"
              itemProp="url"
            >
              <IoLogoInstagram />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              itemProp="url"
              alt="link-tripadvisor"
              href="https://www.tripadvisor.com/Attraction_Review-g1544599-d27742983-Reviews-Ha_Giang_Motorbike_Loop-Ha_Giang_Ha_Giang_Province.html"
            >
              <LiaTripadvisor />
            </a>
          </li>
        </ul>

        <div className="last-content-f">
          <p>@2023-2024 Allrights reserved SunFlowerTours</p>

          <ul>
            <li>
              <img src={VSIc} alt="Visa Logo" />
            </li>
            <li>
              <img src={MTIc} alt="Master Card Logo" />
            </li>
            <li>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/boct2.png?alt=media&token=740b2881-77dc-473a-927b-3a6b5a0c45ea"
                }
                alt="Bo Cong Thuong Logo"
              />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
