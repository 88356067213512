import React from "react";
import Fancybox from "../../Fancybox";

export default function LibrariesImgs({urls}) {

  return (
    <div className="wrap-libraries">
      <div className="l-1">
        <div className="txt-ti">
          <h3 className="header-txt-homep">Our Gallery</h3>
        </div>
      </div>
      <div className="l-2">
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
              <div className="row">
                <div className="col-4">
                  <div className="bu-bs">
                    {
                      Array.isArray(urls) && urls.length > 0 ? (
                        urls.slice(0, 2).map((i, k) => (
                          <div
                            className={`${
                              k === 0
                                ? "wrap-img-in-lib lib-big"
                                : "wrap-img-in-lib lib-sm"
                            }`}
                            key={k}
                          >
                            <div data-fancybox="gallery" data-src={i.url}>
                              <img src={i.url} alt={`${i.url}/${k}`} />
                            </div>
                            {/* {i.type === "img" && (
                              <div className="ab-pl">
                                <div className="ab-pl-ic">
                                  <FaPlay />
                                </div>
                              </div>
                            )} */}
                          </div>
                        ))
                      ) : (<div>None of data</div>)
                    }
                  </div>
                </div>
                <div className="col-4">
                  <div className="bu-bs">
                    {urls.slice(2, 4).map((i, k) => (
                      <div
                        className={`${
                          k === 0
                            ? "wrap-img-in-lib lib-sm"
                            : "wrap-img-in-lib lib-big"
                        }`}
                        key={k}
                      >
                        <div data-fancybox="gallery" data-src={i.url}>
                          <img src={i.url} alt={`${i.url}/${k}`} />
                        </div>
                        {/* {i.type === "img" && (
                        <div className="ab-pl">
                          <p>Ma Pi Leng</p>
                          <div className="ab-pl-ic">
                            <FaPlay />
                          </div>
                        </div>
                      )} */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-4">
                  <div className="bu-bs">
                    {urls.slice(4, 6).map((i, k) => (
                      <div
                        className={`${
                          k === 0
                            ? "wrap-img-in-lib lib-big"
                            : "wrap-img-in-lib lib-sm"
                        }`}
                        key={k}
                      >
                        <div data-fancybox="gallery" data-src={i.url}>
                          <img src={i.url} alt={`${i.url}/${k}`} />
                        </div>{" "}
                        {/* {i.type === "video" && (
                        <div className="ab-pl">
                          <p>Ma Pi Leng</p>
                          <div className="ab-pl-ic">
                            <FaPlay />
                          </div>
                        </div>
                      )} */}
                      </div>
                    ))}
                  </div>
                </div>
                </div>
              </Fancybox>
      </div>
    </div>
  );
}
