import React from "react";
import { IoBookmark } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dataDefaultFetch, getDataAPI } from "../../utils/fetchData";
import { Skeleton } from "antd";
import moment from "moment";
import parse from "html-react-parser";
import BannerFiForPage from "../../components/_shared/ui-blocks/BannerFiForPage";
import { useTitle } from "../../hooks/useTitle"

export default function BlogDetails() {
  const [onePro, setOnePro] = React.useState(dataDefaultFetch);

  const { id } = useParams();
  const navigate = useNavigate();

  if (id && onePro.error) {
    navigate("/blog");
  }

  const {state} = useLocation();

  React.useEffect(() => {
    if (id) {
      (async () => {
        setOnePro({ ...onePro, loading: true });
        try {
          const res = await getDataAPI(`blog/one/${id}`);

          setOnePro({ ...onePro, data: res.data?.data, loading: false });
        } catch (error) {
          setOnePro({ ...onePro, data: null, loading: false, error: error });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useTitle(state?.title);

  return (
    <>
      <BannerFiForPage title={"Blog"} />
      {onePro.data ? (
        <div className="wrapBlogDel container">
          <div className="ct-inf">
            <div className="tabhead">
              <IoBookmark />
              <p>{onePro.data?.categories}</p>
            </div>
            <div className="in">
              <p>{onePro.data?.created_by}</p>
              <p>{moment(onePro.data?.createdAt).format("DD/MM/YYYY")}</p>
            </div>
          </div>

          <h1>{onePro.data?.title}</h1>

          <div className="desc">
            <div>
              <img src={onePro.data?.img.url} alt={onePro.data?.img.name} />
            </div>
            {parse(onePro.data?.desc)}
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
}
