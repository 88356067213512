import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export default function BannerFiForPage({ title }) {
  const navigate = useNavigate();
  return (
    <div className="wrap-bannerfiforpage">
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/vali.jpg?alt=media&token=bbba0123-c34b-4e03-8b46-44824d8f170d"
        }
        alt="Background Title For Each Page"
      />

      <div className="wrap-bannerfiforpage-content">
        <h1>{title}</h1>
        <p>
          <span onClick={() => navigate("/", { replace: true })}>Home</span>{" "}
          {">"} {title}
        </p>
      </div>
    </div>
  );
}
