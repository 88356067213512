
const initialState = {
    token: null,
    user: null,
    loading: false,
    error: null,
}
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "AUTH_REQUEST_LOADING":
            return { ...state, loading: true };
          case "AUTH_REQUEST_SUCCESS":
            return { ...state, loading: false, user: action.payload.user, token: action.payload.token };
          case "AUTH_REQUEST_ERROR":
            return { ...state, loading: false, error: action.payload, status: action?.status };
          case "AUTH_EXIT":
            return {...initialState}
        default:
            return state;

    }
}
export default authReducer