export const USER_TOKEN = "ADMIN_TOKEN";

export const setItem = (key, value) => {
    typeof window !== 'undefined' && localStorage.setItem(key, value);
};

export const setToken = (value) => {
  setItem(USER_TOKEN, value);
};

export const clearToken = () => setToken("");

export const getItem = (key) => {
  const value =  typeof window !== 'undefined' && window.localStorage.getItem(key);
  return value === null ? "" : value;
};

export const getToken = () => getItem(USER_TOKEN);
