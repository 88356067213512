import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import {thunk} from "redux-thunk";


import rootReducer from './reducers/index'


const middleware = [thunk];

const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware))
)

const DataProvider = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}

export default DataProvider