import "./App.scss";

import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
import { Route, Routes } from "react-router-dom";
import Main from "./layouts/Main/Main";
import { Result } from "antd";
import Contact from "./pages/contact-page";
import Blog from "./pages/blog-page"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";
import Tour from "./pages/tour-page"
import FormBook from "./pages/form-book-page"
import HomePage from "./pages/home-page"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "./utils/storage"
import React from "react"
import { refreshToken } from "./redux/actions/authAction"
import LayoutDB from "./layouts/Dashboard"
import Login from "./pages/Admin/Auth/Login"
import MainBlog from "./pages/Admin/Post"
import AddBlog from "./pages/Admin/Post/AddBlog"
import Profile from "./pages/Admin/Profile"
import BlogDetails from "./pages/blog-page/detail"

function App() {

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const tokenStore = getToken();
    if (tokenStore) {
      dispatch(refreshToken({ access_token: tokenStore }));
    }
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<HomePage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/detail/:id" element={<BlogDetails />} />
        <Route path="tour" element={<Tour />} />
        <Route path="book" element={<FormBook />} />
      </Route>

      <Route path="/login" element={auth.token ? <Result status="404" title="404" subTitle="Trang không tồn tại" /> : <Login />} />
      <Route path="/dashboard" element={auth.token ? <LayoutDB /> : <Result status="404" title="404" subTitle="Trang không tồn tại" />}>
        <Route index element={<MainBlog />} />
        <Route path="blog/add" element={<AddBlog />} />
        <Route path="blog/edit/:id" element={<AddBlog />} />
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route
        path="*"
        element={
          <Result status="404" title="404" subTitle="Trang không tồn tại" />
        }
      />
    </Routes>
  );
}

export default App;
